import { goToLoginPage } from '@amedia/user';

function renderAuthenticated(userName, theme, isEmployee, isMockUser) {
  const button = document.createElement('button');
  button.textContent = userName || 'Min side';
  const arrow = document.createElement('amedia-user-arrow');
  if (theme) {
    arrow.classList.add(theme);
  }
  let open = false;
  button.addEventListener('click', () => {
    arrow.toggle();
    open = !open;
    window.dispatchEvent(
      new CustomEvent('aid-menu-open', {
        detail: open,
      })
    );
  });

  button.appendChild(arrow);

  if (isMockUser) {
    arrow.insertAdjacentHTML(
      'beforebegin',
      '<span class="employee">(mock user)</span>'
    );
  } else if (isEmployee) {
    arrow.insertAdjacentHTML(
      'beforebegin',
      '<span class="employee">(ansatt i Amedia)</span>'
    );
  }

  return button;
}

function renderUnAuthenticated(
  publication,
  orderPage,
  subscriptionLink,
  subscriptionText,
  links
) {
  const fragment = document.createDocumentFragment();
  const { userAgent } = window.navigator;

  if (subscriptionLink && !userAgent.includes('pushvarslings-app')) {
    const a = document.createElement('a');
    a.textContent = subscriptionText || 'Bli abonnent!';
    a.setAttribute(
      'href',
      [
        orderPage,
        orderPage.indexOf('?') === -1 ? '?' : '&',
        'ns_campaign=fbm.INTRO',
        '&ns_mchannel=fbm.bliaboknapp',
        '&ns_source=fbm.A',
        '&ns_linkname=fbm.INTRO',
        '&ns_fee=0',
      ].join('')
    );
    fragment.appendChild(a);
  }
  if (links) {
    JSON.parse(decodeURIComponent(links)).forEach((link) => {
      if (link.authenticated) {
        return;
      }
      const a = document.createElement('a');
      a.setAttribute('href', link.url);
      a.textContent = link.text;
      fragment.appendChild(a);
    });
  }
  const login = document.createElement('a');
  login.textContent = 'Logg inn';
  login.href = '';
  login.addEventListener('click', (evt) => {
    evt.preventDefault();
    goToLoginPage({ context: 'ego_top' });
  });
  fragment.appendChild(login);

  return fragment;
}

class AidUsername extends HTMLElement {
  render() {
    const orderPage = this.getAttribute('orderpage');
    const subscriptionLink = this.getAttribute('subscription-link');
    const subscriptionText = this.getAttribute('subscription-text');
    const links = this.getAttribute('links');
    const userName = this.getAttribute('userName');
    const theme = this.getAttribute('theme');
    const publication = this.getAttribute('publication');
    const isMockUser = this.getAttribute('isMockUser') === 'true';
    const isEmployee = this.getAttribute('isEmployee') === 'true';

    const nameElement = this.hasAttribute('authenticated')
      ? renderAuthenticated(userName, theme, isEmployee, isMockUser)
      : renderUnAuthenticated(
          publication,
          orderPage,
          subscriptionLink,
          subscriptionText,
          links
        );
    this.appendChild(nameElement);
    this.rendered = true;
  }

  connectedCallback() {
    if (!this.rendered) {
      this.render();
    }
  }
}

if (customElements && customElements.define) {
  customElements.define('amedia-username', AidUsername);
}
